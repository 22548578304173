import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

import CustomBackdrop from '../../components/CustomBackdrop/CustomBackdrop';
import {
  useCortexConfigQuery,
  useUpdateCortexConfigMutation,
  useUploadFileMutation,
} from '../../gql/generated/graphql';
import { toSnakeCase } from '../../helpers/helpers';
import { useSnackbar } from '../../providers/SnackbarProvider';
import CustomTextField from '../ValidationPage/components/CustomTextField/CustomTextField';

type FormData = {
  postToZapier: boolean;
  maxRecursionDepth: number | null;
  mongoSiteCacheInDays: number | null;
  minFacesForGroupPicture: number | null;
  maxCrawledPagesPerDomain: number | null;
  macAcceptableWebsitePopularity: number | null;
  assessmentXlsUploadTime: string;
  assessmentXlsTemplateId: string;
};

const HARDCODED_COMPANY_ID = -1;

const SettingsPage = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    postToZapier: false,
    maxRecursionDepth: null,
    mongoSiteCacheInDays: null,
    minFacesForGroupPicture: null,
    maxCrawledPagesPerDomain: null,
    macAcceptableWebsitePopularity: null,
    assessmentXlsUploadTime: '',
    assessmentXlsTemplateId: '',
  });

  const { data, loading, refetch: refetchCortexConfig } = useCortexConfigQuery();
  const [uploadFile, { loading: uploadFileLoading }] = useUploadFileMutation();
  const [updateCortexConfig, { loading: updateCortexConfigLoading }] =
    useUpdateCortexConfigMutation();

  useEffect(() => {
    if (data?.cortexConfig) {
      const convertedData: FormData = {
        postToZapier: data.cortexConfig.postToZapier ?? false,
        maxRecursionDepth: data.cortexConfig.maxRecursionDepth ?? null,
        mongoSiteCacheInDays: data.cortexConfig.mongoSiteCacheInDays ?? null,
        minFacesForGroupPicture: data.cortexConfig.minFacesForGroupPicture ?? null,
        maxCrawledPagesPerDomain: data.cortexConfig.maxCrawledPagesPerDomain ?? null,
        macAcceptableWebsitePopularity: data.cortexConfig.macAcceptableWebsitePopularity ?? null,
        assessmentXlsUploadTime: data.cortexConfig.assessmentXlsUploadTime ?? '',
        assessmentXlsTemplateId: data.cortexConfig.assessmentXlsTemplateId ?? '',
      };
      setFormData(convertedData);
    }
  }, [data]);

  const handleChange = (field: keyof FormData, value: any) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleNumberChange = (field: keyof FormData, value: any) => {
    if (!isNaN(value)) {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [field]: null,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const convertedAttributes = Object.keys(formData).reduce(
      (acc, key) => {
        const snakeKey = toSnakeCase(key);
        acc[snakeKey] = formData[key as keyof FormData];
        return acc;
      },
      {} as Record<string, any>,
    );
    const convertedAttributesStringified = JSON.stringify(convertedAttributes);

    const response = await updateCortexConfig({
      variables: {
        attributes: convertedAttributesStringified,
      },
    });

    if (response.data.updateCortexConfig.ok) {
      refetchCortexConfig();
      showSnackbar(t('settingsPage.successMessage'), 'success', false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      setUploadError(t('settingsPage.noFileSelectedError'));
      return;
    }

    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const base64File = (reader.result as string).split(',')[1];

        const response = await uploadFile({
          variables: {
            companyId: HARDCODED_COMPANY_ID,
            file: base64File,
            fileName: file.name,
            isBmFile: true,
          },
        });

        if (response.errors) {
          throw new Error(t('settingsPage.uploadFailedError'));
        }

        refetchCortexConfig();
        setUploadError(null);
        showSnackbar(t('settingsPage.uploadSuccessMessage'), 'success', false);
      } catch (error) {
        setUploadError((error as Error).message || t('settingsPage.genericUploadError'));
      }
    };

    reader.onerror = () => {
      setUploadError(t('settingsPage.fileReadError'));
    };

    reader.readAsDataURL(file);
  };

  const formatNumberToStringValue = (value: number | null) => {
    return value === null ? '' : String(value);
  };

  const isFormIncomplete = Object.values(formData).some((value) => value === '' || value === null);

  const assessmentUploadTime = formData?.assessmentXlsUploadTime
    ? format(new Date(formData.assessmentXlsUploadTime), 'dd.MM.yyyy HH:mm')
    : '';

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h5">{t('settingsPage.editConfig')}</Typography>
      <Box>
        <Button variant="contained" component="label">
          {t('settingsPage.uploadFile')}
          <input type="file" hidden onChange={handleFileUpload} />
        </Button>
        {uploadError && <Typography color="error">{uploadError}</Typography>}
      </Box>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gap={2}
        mt={4}
        onSubmit={handleSubmit}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.postToZapier}
              onChange={(e) => handleChange('postToZapier', e.target.checked)}
            />
          }
          label={t('settingsPage.postToZapier')}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <CustomTextField
              name="maxRecursionDepth"
              label={t('settingsPage.maxRecursionDepth')}
              type="number"
              value={formatNumberToStringValue(formData.maxRecursionDepth)}
              onChange={(e) => handleNumberChange('maxRecursionDepth', e.target.value)}
            />
            <CustomTextField
              name="mongoSiteCacheInDays"
              label={t('settingsPage.mongoSiteCacheInDays')}
              type="number"
              value={formatNumberToStringValue(formData.mongoSiteCacheInDays)}
              onChange={(e) => handleNumberChange('mongoSiteCacheInDays', e.target.value)}
            />
            <CustomTextField
              name="minFacesForGroupPicture"
              label={t('settingsPage.minFacesForGroupPicture')}
              type="number"
              value={formatNumberToStringValue(formData.minFacesForGroupPicture)}
              onChange={(e) => handleNumberChange('minFacesForGroupPicture', e.target.value)}
            />
            <CustomTextField
              name="maxCrawledPagesPerDomain"
              label={t('settingsPage.maxCrawledPagesPerDomain')}
              type="number"
              value={formatNumberToStringValue(formData.maxCrawledPagesPerDomain)}
              onChange={(e) => handleNumberChange('maxCrawledPagesPerDomain', e.target.value)}
            />
            <CustomTextField
              name="macAcceptableWebsitePopularity"
              label={t('settingsPage.macAcceptableWebsitePopularity')}
              type="number"
              value={formatNumberToStringValue(formData.macAcceptableWebsitePopularity)}
              onChange={(e) => handleNumberChange('macAcceptableWebsitePopularity', e.target.value)}
            />
            <CustomTextField
              name="assessmentXlsUploadTime"
              label={t('settingsPage.assessmentXlsUploadTime')}
              disabled
              value={assessmentUploadTime}
              onChange={(e) => handleChange('assessmentXlsUploadTime', e.target.value)}
            />
            <CustomTextField
              name="assessmentXlsTemplateId"
              label={t('settingsPage.assessmentXlsTemplateId')}
              disabled
              value={formData.assessmentXlsTemplateId}
              onChange={(e) => handleChange('assessmentXlsTemplateId', e.target.value)}
            />
            <Button type="submit" variant="contained" color="primary" disabled={isFormIncomplete}>
              {t('settingsPage.updateConfig')}
            </Button>
          </>
        )}
      </Box>
      <CustomBackdrop open={updateCortexConfigLoading || uploadFileLoading} />
    </Box>
  );
};

export default SettingsPage;
