import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { useDebouncedCallback } from 'use-debounce';

import CompanyLinks from '../../components/CompanyLinks/CompanyLinks';
import CustomBackdrop from '../../components/CustomBackdrop/CustomBackdrop';
import SearchComponent from '../../components/Search/Search';
import { useValidation } from '../../contexts/ValidationContext';
import {
  CompanyAssesmentEdge,
  CompanyAssessmentType,
  useGetGenericCrawlResultIdLazyQuery,
  useGetShortCompanyLazyQuery,
  useGetSoftwareFirmsLazyQuery,
  useMarkCompanyAsSoftwareMutation,
} from '../../gql/generated/graphql';
import { getFirstNode } from '../../gql/helpers';
import { paths } from '../../routes/paths';
import CustomPaper from '../DFMPage/components/CustomPaper/CustomPaper';

import FinishStep from './components/ValidationSteps/FinishStep';
import IsSoftwareStep from './components/ValidationSteps/IsSoftwareStep';
import ValidateAddressStep from './components/ValidationSteps/ValidateAddressStep';
import ValidateCustomers from './components/ValidationSteps/ValidateCustomers';
import ValidateKeywords from './components/ValidationSteps/ValidateKeywords';
import ValidateProductCategory from './components/ValidationSteps/ValidateProductCategory';
import ValidateShareholdingManagement from './components/ValidationSteps/ValidateShareholdingManagement';
import ValidateSizeStep from './components/ValidationSteps/ValidateSizeStep';
import ValidateTechnology from './components/ValidationSteps/ValidateTechnology';
import { IsSoftwareCompanyAnswers, ValidationSteps } from './types';

import styles from './styles';

const ValidationPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [options, setOptions] = useState<CompanyAssesmentEdge[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [company, setCompany] = useState<CompanyAssessmentType | null>(null);
  const [companyId, setCompanyId] = useState(null);
  const { companyValidation } = useValidation();

  const companyParamsId = searchParams.get('companyId');
  const validationStep = searchParams.get('step');

  useEffect(() => {
    if (companyParamsId) {
      const companyIdAsNumber = parseInt(companyParamsId);
      setCompanyId(companyIdAsNumber);
      getShortCompany({ variables: { companyId: companyIdAsNumber } });
      getGenericCrawlResultId({
        variables: { companyId: parseInt(companyParamsId) },
      });
    }
  }, []);

  useEffect(() => {
    if (!validationStep) return;
    setActiveStep(Number(validationStep));
  }, [validationStep]);

  const [getShortCompany, { data: shortCompany, loading: shortCompanyLoading }] =
    useGetShortCompanyLazyQuery();

  const [getGenericCrawlResultId, { data: genericCrawlResultIdData }] =
    useGetGenericCrawlResultIdLazyQuery();

  const shortCompanyNode = getFirstNode(shortCompany?.getCompany);

  const genericCrawlResultId = genericCrawlResultIdData?.getCrawlResults?.edges?.[0]?.node?.dbId;

  useEffect(() => {
    if (!shortCompanyNode) return;
    setCompany(shortCompanyNode);
  }, [companyId, shortCompanyNode]);

  const [markCompanyAsSoftware, { loading: markCompanyAsSoftwareLoading }] =
    useMarkCompanyAsSoftwareMutation();

  const debouncedSearch = useDebouncedCallback((value: string) => {
    search({ variables: { searchTerm: value } });
  }, 500);

  const [search, { loading }] = useGetSoftwareFirmsLazyQuery({
    onCompleted: (data) => {
      setOptions(data.softwareFirms.edges);
    },
  });

  const handleOptionSelect = (_, value: CompanyAssesmentEdge | null) => {
    if (value === null) return;
    setCompany(value ? value.node : null);
    setCompanyId(value ? value?.node?.dbId : null);
    setSearchParams({ companyId: String(value.node.dbId) });
    getGenericCrawlResultId({
      variables: { companyId: parseInt(companyParamsId) },
    });
  };

  const handleInputChange = (_, value: string, reason: string) => {
    if (reason === 'clear') {
      handleReset();
      search();
      return;
    }

    setSearchTerm(value);
    if (value) {
      debouncedSearch(value);
    }
  };
  const handleReset = () => {
    setSearchTerm('');
    setOptions([]);
    setCompany(null);
  };

  const handleNext = async () => {
    if (activeStep === ValidationSteps.IS_SOFTWARE && companyValidation?.isSoftware) {
      if (companyValidation?.isSoftware === IsSoftwareCompanyAnswers.YES) {
        const result = await markCompanyAsSoftware({
          variables: { companyId, crawlResultId: genericCrawlResultId },
        });
        if (!result?.data?.markCompanyAsSoftware?.ok) return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackToAssessment = () => {
    navigate(`${paths.evaluation.dfm}?companyId=${companyParamsId}`);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const companyName =
    (company?.shortName !== '<unnamed reference customer>' && company?.shortName) ||
    company?.legalName ||
    company?.uniqueDomain;

  const steps = [
    {
      label: t('validation.steps.isSoftware'),
      component: <IsSoftwareStep companyId={companyId} />,
    },
    {
      label: t('validation.steps.validateSize'),
      component: <ValidateSizeStep companyId={companyId} />,
    },
    {
      label: t('validation.steps.validateAddress'),
      component: <ValidateAddressStep companyId={companyId} onNextStep={handleNext} />,
    },
    {
      label: t('validation.steps.validateShareholdingManagement'),
      component: <ValidateShareholdingManagement companyId={companyId} />,
    },
    {
      label: t('validation.steps.validateProductCategory'),
      component: <ValidateProductCategory companyId={companyId} />,
    },
    {
      label: t('validation.steps.validateKeywords'),
      component: <ValidateKeywords companyId={companyId} />,
    },
    {
      label: t('validation.steps.validateCustomers'),
      component: <ValidateCustomers companyId={companyId} />,
    },
    {
      label: t('validation.steps.validateTechnology'),
      component: <ValidateTechnology companyId={companyId} />,
    },
    {
      label: t('validation.steps.finish'),
      component: <FinishStep companyId={companyId} />,
    },
  ];

  const nextStepIsEnabled = (() => {
    switch (activeStep) {
      case ValidationSteps.IS_SOFTWARE:
        return companyValidation.isSoftware === 'yes';
      default:
        return true;
    }
  })();

  const nextOrBackToAssessment = validationStep ? handleBackToAssessment : handleNext;

  return (
    <Box>
      <SearchComponent
        options={options}
        loading={loading}
        searchTerm={searchTerm}
        onInputChange={handleInputChange}
        onOptionSelect={handleOptionSelect}
        onReset={handleReset}
      />
      <Typography textAlign="center" variant="h5" mt={3}>
        {t('validation.validationTitle')}: {companyName}
      </Typography>
      {company && (
        <>
          <MobileStepper
            variant="dots"
            steps={steps?.length}
            position="static"
            activeStep={activeStep}
            sx={styles.mobileStepper}
            nextButton={
              <Button
                size="small"
                onClick={nextOrBackToAssessment}
                disabled={activeStep === steps?.length - 1 || !nextStepIsEnabled}
              >
                {activeStep === ValidationSteps.FINISH
                  ? t('validation.finish')
                  : validationStep
                    ? t('validation.backToAssessmentButtonLabel')
                    : t('validation.nextPage')}
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0 || Boolean(validationStep)}
              >
                <KeyboardArrowLeft />
                {t('validation.previousPage')}
              </Button>
            }
          />

          <CustomPaper sx={styles.customPaper}>
            <Typography textAlign="center" fontWeight="medium" variant="body2">
              {steps[activeStep].label}
            </Typography>
            <Box mt={4} />
            <Box mb={2}>
              <CompanyLinks
                uniqueDomain={company?.uniqueDomain}
                linkedinUrl={company?.linkedinUrl}
              />
            </Box>
            {steps[activeStep]?.component}
          </CustomPaper>
        </>
      )}
      <CustomBackdrop open={markCompanyAsSoftwareLoading} />
    </Box>
  );
};

export default ValidationPage;
