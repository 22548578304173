import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import i18n from '../../locales/i18n';

export type CpcCompetitionKeywordsRows = {
  keyword: string;
  cpc: number;
  dbId: number;
};

type SoftwareGrowthRatesRows = {
  term: string;
  avgAlltimeInterest: number;
  interestGrowthRate: number;
  avgInterestLast12Months: number;
  avgInterest24To12MonthsAgo: number;
  dbId: number;
};

type CustomerIndustryGrowthRows = {
  sicDesc: string;
  adjustedMedianGrowth: number;
  sampleSize: number;
  dbId: number;
};

type EmployeStaticsRows = {
  medianEmploymentMonths: number;
  medianTotalEmploymentMonths: number;
  medianPreviousEmployers: number;
  numberOfEmployees: number;
  dbId: number;
};

type PrecalculatedKpisRows = {
  salesGrowth: number;
  absEbit: number;
  softwareCategoryGrowth: number;
  pricePerGoogleKeywordClick: number;
  mostExpensiveKeyword: string;
  customerIndustryGrowth: number;
  employeeConcentrationRatio: number;
  teamQuality: number;
  ageOfProgrammingLanguage: number;
  popularityOfProgrammingLanguage: number;
  useOfBackendFramework: boolean;
  useOfFrontendFramework: boolean;
};

export const softwareGrowthRatesColumns: GridColDef<[SoftwareGrowthRatesRows]>[] = [
  { field: 'term', headerName: 'Term', width: 300 },
  {
    field: 'interestGrowthRate',
    headerName: i18n.t('assessment.dataGridsColumns.interestGrowthRate'),
    headerAlign: 'left',
    cellClassName: 'number-cell',
    valueFormatter: (value: number) => {
      if (typeof value === 'number') {
        return `${(value * 100).toFixed(2)}%`;
      }
      return value;
    },
    width: 170,
    editable: true,
    align: 'left',
  },
  {
    field: 'avgInterest24To12MonthsAgo',
    headerName: i18n.t('assessment.dataGridsColumns.avgInterest24To12MonthsAgo'),
    headerAlign: 'center',
    valueFormatter: (value: number) => value?.toFixed(2),
    width: 200,
    editable: false,
    align: 'center',
  },
  {
    field: 'avgInterestLast12Months',
    headerName: i18n.t('assessment.dataGridsColumns.avgInterestLast12Months'),
    headerAlign: 'center',
    valueFormatter: (value: number) => value?.toFixed(2),
    width: 200,
    editable: true,
    align: 'center',
  },
  {
    field: 'avgAlltimeInterest',
    headerName: i18n.t('assessment.dataGridsColumns.avgAlltimeInterest'),
    headerAlign: 'right',
    valueFormatter: (value: number) => value?.toFixed(2),
    width: 200,
    editable: false,
    description: 'Description!',
    align: 'right',
  },
];

export const cpcCompetitionKeywordsColumns: GridColDef<[CpcCompetitionKeywordsRows]>[] = [
  { field: 'keyword', headerName: i18n.t('assessment.dataGridsColumns.keyword'), width: 250 },
  {
    field: 'cpc',
    headerName: i18n.t('assessment.dataGridsColumns.cpc'),
    width: 100,
    editable: false,
    valueFormatter: (value: number) => {
      if (value === -1) return 'n.a.'; // Replace -1 with 'n.a.'
      if (value == null) return ''; // Handle null/undefined values gracefully
      return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);
    },
  },
];

export const customerIndustryGrowthColumns: GridColDef<[CustomerIndustryGrowthRows]>[] = [
  { field: 'sicDesc', headerName: i18n.t('assessment.dataGridsColumns.sicDesc'), width: 350 },
  {
    field: 'adjustedMedianGrowth',
    headerName: i18n.t('assessment.dataGridsColumns.adjustedMedianGrowth'),
    width: 200,
    editable: false,
    valueFormatter: (value: number) => {
      return value !== null && value !== undefined ? (value * 100).toFixed(1) + '%' : '';
    },
  },
  {
    field: 'sampleSize',
    headerName: i18n.t('assessment.dataGridsColumns.sampleSize'),
    width: 100,
    editable: false,
  },
];

export const employeStaticsColumns: GridColDef<[EmployeStaticsRows]>[] = [
  {
    field: 'medianEmploymentMonths',
    headerName: i18n.t('assessment.dataGridsColumns.medianEmploymentMonths'),
    width: 200,
    valueFormatter: (value: number) => value?.toFixed(2),
  },
  {
    field: 'medianTotalEmploymentMonths',
    headerName: i18n.t('assessment.dataGridsColumns.medianTotalEmploymentMonths'),
    width: 200,
    editable: false,
    valueFormatter: (value: number) => value?.toFixed(2),
  },
  {
    field: 'medianPreviousEmployers',
    headerName: i18n.t('assessment.dataGridsColumns.medianPreviousEmployers'),
    width: 200,
    editable: false,
  },
  {
    field: 'numberOfEmployees',
    headerName: i18n.t('assessment.dataGridsColumns.numberOfEmployees'),
    width: 100,
    editable: false,
  },
];

export const precalculatedOnlineKpisColumns: GridColDef<PrecalculatedKpisRows>[] = [
  {
    field: 'onlineVisibility',
    headerName: i18n.t('assessment.dataGridsColumns.onlineVisibility'),
    width: 200,
    valueFormatter: (value: number) => `${value?.toFixed(2)}`,
  },
  {
    field: 'pricePerGoogleKeywordClick',
    headerName: i18n.t('assessment.dataGridsColumns.pricePerGoogleKeywordClick'),
    width: 250,
  },
  {
    field: 'mostExpensiveKeyword',
    headerName: i18n.t('assessment.dataGridsColumns.mostExpensiveKeyword'),
    width: 200,
  },
];
export const precalculatedTechKpisColumns: GridColDef<PrecalculatedKpisRows>[] = [
  {
    field: 'ageOfProgrammingLanguage',
    headerName: i18n.t('assessment.dataGridsColumns.ageOfProgrammingLanguage'),
    width: 200,
  },
  {
    field: 'popularityOfProgrammingLanguage',
    headerName: i18n.t('assessment.dataGridsColumns.popularityOfProgrammingLanguage'),
    width: 200,
    valueFormatter: (value: number) => {
      if (value == null) return ''; // Handle null/undefined values gracefully
      return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(value);
    },
  },
  {
    field: 'useOfBackendFramework',
    headerName: i18n.t('assessment.dataGridsColumns.useOfBackendFramework'),
    width: 200,
    renderCell: (params) => {
      return params?.formattedValue ? (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          <DoneIcon fontSize="small" />
        </Box>
      ) : null;
    },
  },
  {
    field: 'useOfFrontendFramework',
    headerName: i18n.t('assessment.dataGridsColumns.useOfFrontendFramework'),
    width: 200,
    renderCell: (params) => {
      return params?.formattedValue ? (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          <DoneIcon fontSize="small" />
        </Box>
      ) : null;
    },
  },
];
export const precalculatedFinanceKpisColumns: GridColDef<PrecalculatedKpisRows>[] = [
  {
    field: 'softwareShare',
    headerName: i18n.t('assessment.dataGridsColumns.softwareShare'),
    headerAlign: 'left',
    width: 150,
    align: 'left',
    valueFormatter: (value: number) => {
      if (value == null) return ''; // Handle null/undefined values gracefully
      return value !== null && value !== undefined ? (value * 100).toFixed(1) + '%' : '';
    },
  },
  {
    field: 'recurringRevenueShare',
    headerName: i18n.t('assessment.dataGridsColumns.recurringShare'),
    headerAlign: 'center',
    width: 150,
    align: 'center',
    valueFormatter: (value: number) => {
      if (value == null) return ''; // Handle null/undefined values gracefully
      return value !== null && value !== undefined ? (value * 100).toFixed(1) + '%' : '';
    },
  },
  {
    field: 'salesGrowth',
    headerName: i18n.t('assessment.dataGridsColumns.salesGrowth'),
    headerAlign: 'center',
    width: 150,
    align: 'center',
    valueFormatter: (value: number) => {
      if (value == null) return ''; // Handle null/undefined values gracefully
      return value !== null && value !== undefined ? (value * 100).toFixed(1) + '%' : '';
    },
  },
  {
    field: 'absEbit',
    headerName: i18n.t('assessment.dataGridsColumns.absEbit'),
    headerAlign: 'center',
    width: 150,
    align: 'center',
    valueFormatter: (value: number) => {
      if (value == null) return ''; // Handle null/undefined values gracefully
      return value !== null && value !== undefined ? value.toFixed(0).toLocaleString() + '' : '';
    },
  },
  {
    field: 'cogsRatio',
    headerName: i18n.t('assessment.dataGridsColumns.cogsRatio'),
    headerAlign: 'center',
    align: 'center',
    width: 150,
    valueFormatter: (value: number) => {
      if (value == null) return ''; // Handle null/undefined values gracefully
      return value !== null && value !== undefined ? (value * 100).toFixed(1) + '%' : '';
    },
  },
  {
    field: 'cLevelQuality',
    headerName: i18n.t('assessment.dataGridsColumns.cLevelQuality'),
    width: 150,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (value: number) => {
      if (value == null) return ''; // Handle null/undefined values gracefully
      return value !== null && value !== undefined ? (value * 100).toFixed(1) + '%' : '';
    },
  },
  {
    field: 'netNewCustomerSalesGrowth',
    headerName: i18n.t('assessment.dataGridsColumns.netNewCustomerGrowth'),
    width: 220,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (value: number) => {
      if (value == null) return ''; // Handle null/undefined values gracefully
      return value !== null && value !== undefined ? (value * 100).toFixed(1) + '%' : '';
    },
  },
  {
    field: 'customerLifetimeValueDistribution',
    headerName: i18n.t('assessment.dataGridsColumns.cltv'),
    width: 150,
    headerAlign: 'right',
    align: 'right',
    valueFormatter: (value: number) => {
      if (value == null) return ''; // Handle null/undefined values gracefully
      return value !== null && value !== undefined ? (value * 100).toFixed(1) + '%' : '';
    },
  },
];
