import { useTranslation } from 'react-i18next';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CustomBackdrop from '../../../../components/CustomBackdrop/CustomBackdrop';
import { useGetShareholdersQuery } from '../../../../gql/generated/graphql';

type Props = {
  companyId: number;
};

const ValidateShareholdingManagement = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const { data, loading: loadingShareholders } = useGetShareholdersQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const shareholdersRows = data?.getCompany?.edges?.[0]?.node?.shareholders?.edges?.map(
    (edge) => edge?.node,
  );

  const shareholdersColumns = [
    {
      field: 'name',
      width: 200,
      headerName: t('validation.validateShareholdingManagement.shareholdersColumnsNameLabel'),
    },
    {
      field: 'isPe',
      width: 250,
      headerName: t('validation.validateShareholdingManagement.shareholdersColumnsIsPeLabel'),
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {params?.formattedValue ? <DoneIcon fontSize="small" /> : null}
        </Box>
      ),
    },
    {
      field: 'isTradeBuyer',
      width: 250,
      headerName: t(
        'validation.validateShareholdingManagement.shareholdersColumnsIsTradeBuyerLabel',
      ),
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {params?.formattedValue ? <DoneIcon fontSize="small" /> : null}
        </Box>
      ),
    },
    {
      field: 'isNaturalPerson',
      width: 250,
      headerName: t(
        'validation.validateShareholdingManagement.shareholdersColumnsIsNaturalPersonLabel',
      ),
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {params?.formattedValue ? <DoneIcon fontSize="small" /> : null}
        </Box>
      ),
    },
    // TODO: Implement actions when backend will be ready
    // {
    //   field: 'actions',
    //   headerName: '',
    //   editable: false,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <>
    //       <IconButton size="small" onClick={() => {}}>
    //         <Tooltip title={''}>
    //           <DeleteOutlineIcon fontSize="small" />
    //         </Tooltip>
    //       </IconButton>
    //     </>
    //   ),
    // },
  ];

  // TODO: Implement actions when backend will be ready
  const handleAdd = async (row) => {};
  const handleEdit = async (row) => {};
  const handleDelete = async (row) => {};

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <DataGrid
        disableRowSelectionOnClick
        rows={shareholdersRows || []}
        loading={loadingShareholders}
        columns={shareholdersColumns}
        rowHeight={35}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 80]}
        getRowId={(row) => row?.dbId}
      />
      <CustomBackdrop open={false} />
    </Box>
  );
};

export default ValidateShareholdingManagement;
