export enum ValidationSteps {
  IS_SOFTWARE,
  VALIDATE_SIZE,
  VALIDATE_ADDRESS,
  VALIDATE_SHAREHOLDING_MANAGEMENT,
  VALIDATE_PRODUCT_CATEGORY,
  VALIDATE_KEYWORDS,
  VALIDATE_CUSTOMERS,
  VALIDATE_TECHNOLOGY,
  FINISH,
}

export enum IsSoftwareCompanyAnswers {
  YES = 'yes',
  NO = 'no',
}

export enum CompanySizeType {
  IMAGE = 1,
  TEXT = 2,
  LINKEDIN = 4,
  OTHER = 3,
}

export enum ProductCategories {
  CATEGORY = 'category',
  SUB_CATEGORY = 'subCategory',
  SUB_CATEGORY3 = 'subCategory3',
}

export enum TechnologyCategories {
  CATEGORY = 'category',
  TECHNOLOGY = 'technology',
}
