import { ResourcesConfig } from 'aws-amplify';

export const awsExports: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_DOMAIN,
          scopes: ['openid'],
          redirectSignIn: [
            'http://localhost:3000/',
            'https://ai.bid.pe/',
            'https://cortex.bid.pe/',
          ],
          redirectSignOut: [
            'http://localhost:3000/',
            'https://ai.bid.pe/',
            'https://cortex.bid.pe/',
          ],
          responseType: 'code',
        },
      },
    },
  },
  API: {
    GraphQL: {
      endpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
      region: process.env.REACT_APP_AWS_REGION,
      defaultAuthMode: 'apiKey', // Or 'AMAZON_COGNITO_USER_POOLS', 'AWS_IAM'
      apiKey: process.env.REACT_APP_APPSYNC_API_KEY, // Required if using 'API_KEY'
    },
  },
};
