import { useTranslation } from 'react-i18next';

import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LoupeIcon from '@mui/icons-material/Loupe';
import { Box, Typography } from '@mui/material';

import CustomLink from '../../pages/DFMPage/components/CustomLink/CustomLink';

type Props = {
  uniqueDomain: string;
  linkedinUrl: string;
  pipedriveID?: string;
};

const CompanyLinks = ({ uniqueDomain, linkedinUrl, pipedriveID }: Props) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      {/* Unique Domain Link */}
      <Box display="flex" alignItems="center" gap={1}>
        <LanguageIcon />
        {uniqueDomain ? (
          <CustomLink to={uniqueDomain}>{t('assessment.visitPage')}</CustomLink>
        ) : (
          <Typography color={'action.disabled'}>{t('assessment.visitPage')}</Typography>
        )}
      </Box>

      {/* LinkedIn Link */}
      <Box display="flex" alignItems="center" gap={1}>
        <LinkedInIcon />
        {linkedinUrl ? (
          <CustomLink to={linkedinUrl}>{t('assessment.visitLinkedin')}</CustomLink>
        ) : (
          <Typography color={'action.disabled'}>{t('assessment.visitLinkedin')}</Typography>
        )}
      </Box>

      {/* Conditionally render Pipedrive link if pipedriveID is passed */}
      {pipedriveID && (
        <Box display="flex" alignItems="center" gap={1}>
          <LoupeIcon /> {/* Replace with the actual icon for Pipedrive */}
          <CustomLink to={`https://bid.pipedrive.com/organization/${pipedriveID}`}>
            {t('assessment.visitPD')}
          </CustomLink>
        </Box>
      )}
    </Box>
  );
};

export default CompanyLinks;
