import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';

import {
  CompanyAssesmentEdge,
  CompanyAssessmentType,
  CompanyEdge,
  useGetSoftwareFirmsLazyQuery,
} from '../../gql/generated/graphql';
import CustomLink from '../../pages/DFMPage/components/CustomLink/CustomLink';
import SearchComponent from '../Search/Search';

type Props = {
  open: boolean;
  company: CompanyEdge['node'];
  onClose: () => void;
};

const MergeOrganizations = ({ open, company, onClose }: Props) => {
  const { t } = useTranslation();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
  const [options, setOptions] = useState<CompanyAssesmentEdge[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [companyToCompare, setcompanyToCompare] = useState<CompanyAssessmentType | null>(null);

  const [search, { loading }] = useGetSoftwareFirmsLazyQuery({
    onCompleted: (data) => {
      setOptions(data.softwareFirms.edges);
    },
  });

  useEffect(() => {
    return () => {
      setOptions([]);
      setcompanyToCompare(null);
      setSearchTerm('');
    };
  }, []);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedCompanyId(event.target.value);
  };

  const handleClose = () => {
    onClose();
    handleReset();
  };

  const debouncedSearch = useDebouncedCallback((value: string) => {
    search({ variables: { searchTerm: value } });
  }, 500);

  const handleOptionSelect = (_, value: CompanyAssesmentEdge | null) => {
    if (value === null) return;
    setcompanyToCompare(value ? value.node : null);
  };

  const handleInputChange = (_, value: string, reason: string) => {
    if (reason === 'clear') {
      handleReset();
      search();
      return;
    }

    setSearchTerm(value);
    if (value) {
      debouncedSearch(value);
    }
  };
  const handleReset = () => {
    setSearchTerm('');
    setOptions([]);
    setcompanyToCompare(null);
    setSelectedCompanyId(null);
  };

  const handleMerge = () => {
    // TODO: Implement merge mutation after backend will be done
    console.log('Selected company:', selectedCompanyId);
    handleClose();
  };

  const fieldsToCompare = [
    'linkedinUrl',
    'foundingYear',
    'sicCode',
    'datetimeCreated',
    'datetimeModified',
  ];

  const formatDate = (date: string) => format(new Date(date), 'dd.MM.yyyy HH:mm');

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{t('assessment.mergeOrganizationsDialogTitle')}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          {companyToCompare ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>{t('assessment.mergeOrganizationsDialogCompanyName')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{company?.shortName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{companyToCompare?.shortName}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fieldsToCompare.map((field) => (
                  <TableRow key={field}>
                    <TableCell>{field}</TableCell>
                    <TableCell>
                      {field === 'linkedinUrl' ? (
                        <CustomLink to={company[field]}>{company[field]}</CustomLink>
                      ) : field === 'datetimeCreated' ? (
                        formatDate(company[field])
                      ) : field === 'datetimeModified' ? (
                        formatDate(company[field])
                      ) : (
                        company[field]
                      )}
                    </TableCell>
                    <TableCell>
                      {field === 'linkedinUrl' ? (
                        <CustomLink to={companyToCompare[field]}>
                          {companyToCompare[field]}
                        </CustomLink>
                      ) : field === 'datetimeCreated' ? (
                        formatDate(companyToCompare[field])
                      ) : field === 'datetimeModified' ? (
                        formatDate(companyToCompare[field])
                      ) : (
                        companyToCompare[field]
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography>
                          {t('assessment.mergeOrganizationsDialogCompanyName')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{company?.shortName}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fieldsToCompare.map((field) => (
                      <TableRow key={field}>
                        <TableCell>{field}</TableCell>
                        <TableCell>
                          {field === 'linkedinUrl' ? (
                            <CustomLink to={company[field]}>{company[field]}</CustomLink>
                          ) : field === 'datetimeCreated' ? (
                            formatDate(company[field])
                          ) : field === 'datetimeModified' ? (
                            formatDate(company[field])
                          ) : (
                            company[field]
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={6}>
                <SearchComponent
                  options={options}
                  loading={loading}
                  searchTerm={searchTerm}
                  onInputChange={handleInputChange}
                  onOptionSelect={handleOptionSelect}
                  onReset={handleReset}
                />
              </Grid>
            </Grid>
          )}
        </Box>
        {companyToCompare && (
          <Box mt={2} pt={2}>
            <Typography variant="body2" mb={1}>
              <Typography>{t('assessment.mergeOrganizationsDialogFormTitle')}</Typography>
            </Typography>
            <RadioGroup
              value={selectedCompanyId}
              onChange={handleRadioChange}
              row
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <FormControlLabel
                value={company?.dbId}
                control={<Radio />}
                label={`${t('assessment.mergeOrganizationsDialogFormLabel')} (${company?.shortName})`}
              />
              <FormControlLabel
                value={companyToCompare?.dbId}
                control={<Radio />}
                label={`${t('assessment.mergeOrganizationsDialogFormLabel')} (${companyToCompare?.shortName})`}
              />
            </RadioGroup>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleMerge}
          disabled={!selectedCompanyId}
        >
          {t('assessment.mergeOrganizationsDialogMergeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MergeOrganizations;
