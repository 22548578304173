import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, CircularProgress, Typography } from '@mui/material';
import { useTreeViewApiRef } from '@mui/x-tree-view';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import { GetAllSicCodesQuery } from '../../gql/generated/graphql';

type TreeNode = {
  children: { [key: string]: TreeNode };
  description: string;
  sicCodeStr?: string;
  exists: boolean;
  isLeaf: boolean;
};

type Props = {
  data: GetAllSicCodesQuery;
  loading: boolean;
  selectedSicCodes: string[];
  sicCodeOpenAI?: string;
  onCheckboxChange: Dispatch<SetStateAction<string[]>>;
};

const buildTree = (
  edges?: GetAllSicCodesQuery['getAllSicCodes']['edges'],
): { [key: string]: TreeNode } => {
  if (!edges || edges.length === 0) return {};

  const tree: { [key: string]: TreeNode } = {};
  const existingCodes = new Set(edges.map(({ node }) => node.sicCodeStr));
  const descriptions = new Map(edges.map(({ node }) => [node.sicCodeStr, node.description]));

  const getPrefixes = (code: string): string[] => {
    const prefixes: string[] = [];
    for (let i = 1; i <= code.length; i++) {
      prefixes.push(code.substring(0, i));
    }
    return prefixes;
  };

  edges.forEach(({ node }) => {
    const { sicCodeStr } = node;
    if (!sicCodeStr) return;

    const prefixes = getPrefixes(sicCodeStr);

    let currentLevel = tree;
    prefixes.forEach((prefix) => {
      if (!currentLevel[prefix]) {
        const existingDescription = descriptions.get(prefix);
        currentLevel[prefix] = {
          children: {},
          description: existingDescription
            ? `${prefix} - ${existingDescription}`
            : `${prefix} - n.a.`,
          sicCodeStr: prefix,
          exists: existingCodes.has(prefix),
          isLeaf: false,
        };
      }
      currentLevel = currentLevel[prefix].children;
    });
  });

  const updateNodeProperties = (node: TreeNode, code: string) => {
    node.isLeaf = Object.keys(node.children).length === 0;
    if (!node.description) {
      node.description = `${code} - n.a.`;
    }

    Object.entries(node.children).forEach(([childCode, childNode]) => {
      updateNodeProperties(childNode, childCode);
    });
  };

  Object.entries(tree).forEach(([code, node]) => {
    updateNodeProperties(node, code);
  });

  return tree;
};

const SicCodesTree = ({
  data,
  loading,
  selectedSicCodes,
  sicCodeOpenAI,
  onCheckboxChange,
}: Props) => {
  const { t } = useTranslation();
  const apiRef = useTreeViewApiRef();
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [treeData, setTreeData] = useState<{ [key: string]: TreeNode }>({});

  useEffect(() => {
    const edges = data?.getAllSicCodes?.edges;
    if (edges) {
      setTreeData(buildTree(edges));
    }
  }, [data]);

  useEffect(() => {
    if (sicCodeOpenAI && apiRef.current) {
      const newExpandedItems: string[] = [];
      let code = '';

      for (let i = 1; i <= sicCodeOpenAI.length; i++) {
        code = sicCodeOpenAI.substring(0, i);
        newExpandedItems.push(code);
      }

      newExpandedItems.forEach((itemId) => {
        // @ts-ignore
        apiRef.current?.setItemExpansion({} as React.SyntheticEvent, itemId, true);
      });

      setExpandedItems(newExpandedItems);

      if (!selectedSicCodes.includes(sicCodeOpenAI)) {
        onCheckboxChange((prev) => [...prev, sicCodeOpenAI]);
      }
    }
  }, [sicCodeOpenAI, apiRef, selectedSicCodes, onCheckboxChange]);

  const getAllChildCodes = useCallback((node: TreeNode): string[] => {
    let codes: string[] = [];
    if (node.sicCodeStr) {
      codes.push(node.sicCodeStr);
    }
    Object.values(node.children).forEach((child) => {
      codes = codes.concat(getAllChildCodes(child));
    });
    return codes;
  }, []);

  const isNodeFullySelected = useCallback(
    (node: TreeNode): boolean => {
      const childCodes = getAllChildCodes(node);
      return childCodes.every((code) => selectedSicCodes.includes(code));
    },
    [selectedSicCodes, getAllChildCodes],
  );

  const isNodePartiallySelected = useCallback(
    (node: TreeNode): boolean => {
      const childCodes = getAllChildCodes(node);
      return (
        childCodes.some((code) => selectedSicCodes.includes(code)) && !isNodeFullySelected(node)
      );
    },
    [selectedSicCodes, getAllChildCodes, isNodeFullySelected],
  );

  const handleCheckboxChange = useCallback(
    (node: TreeNode) => {
      const childCodes = getAllChildCodes(node);
      const isFullySelected = isNodeFullySelected(node);

      onCheckboxChange((prev) => {
        if (isFullySelected) {
          return prev.filter((code) => !childCodes.includes(code));
        } else {
          const newCodes = [...prev];
          childCodes.forEach((code) => {
            if (!newCodes.includes(code)) {
              newCodes.push(code);
            }
          });
          return newCodes;
        }
      });
    },
    [getAllChildCodes, isNodeFullySelected, onCheckboxChange],
  );

  const handleExpandedItemsChange = (event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  };

  const renderTree = useCallback(
    (node: TreeNode, key: string) => {
      const isPartiallySelected = isNodePartiallySelected(node);
      const isFullySelected = isNodeFullySelected(node);

      const handleCheckboxClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        handleCheckboxChange(node);
      };

      return (
        <TreeItem
          key={key}
          itemId={key}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Box
                onClick={handleCheckboxClick}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: 1,
                  cursor: 'pointer',
                }}
              >
                <Checkbox
                  checked={isFullySelected}
                  indeterminate={isPartiallySelected}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                />
              </Box>
              <Box sx={{ ml: 1 }}>{node.description}</Box>
            </Box>
          }
        >
          {Object.keys(node.children).map((childKey) =>
            renderTree(node.children[childKey], childKey),
          )}
        </TreeItem>
      );
    },
    [isNodePartiallySelected, isNodeFullySelected, handleCheckboxChange],
  );

  if (loading) {
    return (
      <Box textAlign="center" display="flex" justifyContent="center" alignItems="center" pt={14}>
        <CircularProgress size="small" />
      </Box>
    );
  }

  if (!data?.getAllSicCodes?.edges || data.getAllSicCodes.edges.length === 0) {
    return (
      <Box textAlign="center" pt={2}>
        <Typography>No SIC codes available</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="subtitle2">{t('customerIndustriesPage.sicCodesTree')}</Typography>
      <SimpleTreeView
        apiRef={apiRef}
        expandedItems={expandedItems}
        onExpandedItemsChange={handleExpandedItemsChange}
      >
        {Object.keys(treeData).map((key) => renderTree(treeData[key], key))}
      </SimpleTreeView>
    </Box>
  );
};

export default SicCodesTree;
