import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LazyQueryExecFunction } from '@apollo/client';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import * as Sentry from '@sentry/react';
import { useDebouncedCallback } from 'use-debounce';
import * as XLSX from 'xlsx';

import CustomBackdrop from '../../../components/CustomBackdrop/CustomBackdrop';
import { FLAG_URL } from '../../../contants';
import {
  DealAssessmentFileUrlQuery,
  Exact,
  InputMaybe,
  Scalars,
  useDeleteAllBmForCompanyMutation,
  useEditCompanyMutation,
  useGetUploadedMetaDataQuery,
  useSearchCompanyLazyQuery,
  useStartCrawlMutation,
} from '../../../gql/generated/graphql';
import usePagination from '../../../hooks/usePagination';

import { SmallCheckIcon } from './../components/SmallCheckIcon';
import { SmallCloseIcon } from './../components/SmallCloseIcon';
import Search from './Search';

import { validateBenchmarkingExcelStyles } from './styles';

type ValidateBenchmarkingExcelProps = {
  fileContents: ArrayBuffer | null;
  onGenerationTriggered: LazyQueryExecFunction<
    DealAssessmentFileUrlQuery,
    Exact<{
      companyId?: InputMaybe<Scalars['Int']['input']>;
    }>
  >;
  onValidationComplete: (success: boolean, companyID: number | null) => void;
};

const ValidateBenchmarkingExcel: React.FC<ValidateBenchmarkingExcelProps> = ({
  fileContents,
  onValidationComplete,
  onGenerationTriggered,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [latestUploadRemoved, setLatestUploadRemoved] = useState(false);
  const [crawlDomain, setCrawlDomain] = useState('');
  const [excelData, setExcelData] = useState({
    validated: false,
    metaData: null,
    plData: null,
    bsData: null,
    fteData: null,
    customerData: null,
    customerList: null,
    pricingData: null,
    earliestYear: null,
    pipedriveId: null,
  });

  const [latestUpload, setLatestUpload] = useState<{ pipedriveId: number } | null>(null);
  const {
    data: metaDataResult,
    loading: metaDataResultLoading,
    refetch: refetchMetaData,
  } = useGetUploadedMetaDataQuery({
    variables: { pipedriveId: latestUpload?.pipedriveId },
    skip: !latestUpload?.pipedriveId,
  });

  const [editCompany, { loading: isEditCompanyLoading }] = useEditCompanyMutation();
  const [startCrawl, { loading: startCrawlLoading }] = useStartCrawlMutation();
  const [deleteAllBMUploads, { loading: deleteAllBMUploadsLoading }] =
    useDeleteAllBmForCompanyMutation();

  const [getCompanies, { data: companiesData, loading: getCompaniesLoading, fetchMore }] =
    useSearchCompanyLazyQuery();

  const { paginationModel, isLoading, handlePaginationChange } = usePagination({
    fetchMore,
    pageInfo: companiesData?.cortexFirms?.pageInfo,
  });

  useEffect(() => {
    if (metaDataResult?.uploadedMetaDataByPipedriveId?.company?.dbId) {
      onGenerationTriggered({
        variables: {
          companyId: metaDataResult?.uploadedMetaDataByPipedriveId?.company?.dbId,
        },
      });
    }
  }, [metaDataResult]);

  const debouncedSearch = useDebouncedCallback((value: string) => {
    getCompanies({ variables: { searchTerm: value } });
  }, 500);

  const companiesNodes = companiesData?.cortexFirms?.edges?.map((node) => node?.node);

  useEffect(() => {
    if (!fileContents) return;

    try {
      const workbook = XLSX.read(new Uint8Array(fileContents), {
        type: 'array',
      });
      const metaData = workbook.Sheets['I_Steering'];
      const checks = workbook.Sheets['I_Check'];
      const plData = workbook.SheetNames.includes('PnL') && checks?.['K10']?.v === 'P';
      const bsData = workbook.SheetNames.includes('BS') && checks?.['O10']?.v === 'P';
      const fteData = workbook.SheetNames.includes('FTE') && checks?.['AA10']?.v === 'P';
      const customerData =
        workbook.SheetNames.includes('Customers_aggregated') && checks?.['S10']?.v === 'P';
      const customerList =
        workbook.SheetNames.includes('Customers_list') && checks?.['W10']?.v === 'P';
      const pricingData = workbook.SheetNames.includes('Pricing') && checks?.['AE10']?.v === 'P';

      const validated = plData && bsData && fteData && customerData && customerList && pricingData;
      let earliestYear = null;
      if (plData) {
        const worksheet = workbook.Sheets['PnL'];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const columnI = data.map((row) => row[8]).filter((value) => typeof value === 'number');
        earliestYear = Math.min(...columnI);
      }

      let pipedriveId = null;
      if (workbook.SheetNames.includes('I_Steering')) {
        const steeringSheet = workbook.Sheets['I_Steering'];
        pipedriveId = steeringSheet['D9']?.v;
        pipedriveId = parseInt(pipedriveId, 10);

        if (!isNaN(pipedriveId)) {
          setLatestUpload({ pipedriveId });
        } else {
          pipedriveId = null;
        }
      }

      setExcelData({
        validated,
        metaData,
        plData,
        bsData,
        fteData,
        customerData,
        customerList,
        pricingData,
        earliestYear,
        pipedriveId,
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error processing Excel file:', error);
    }
  }, [fileContents]);

  useEffect(() => {
    if (metaDataResult && metaDataResult.uploadedMetaDataByPipedriveId && excelData.validated) {
      onValidationComplete(true, metaDataResult.uploadedMetaDataByPipedriveId.company.dbId);
    } else {
      onValidationComplete(false, null);
    }
  }, [metaDataResult, onValidationComplete]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value);
    if (event.target.value) {
      debouncedSearch(event.target.value);
    }
  };

  const handleReset = () => {
    setSearchTerm('');
    const event = { target: { value: '' } } as ChangeEvent<HTMLInputElement>;
    handleInputChange(event);
  };

  const handleLinkCompany = async (id: number) => {
    if (!id) return;
    await editCompany({
      variables: {
        companyId: id,
        pipedriveId: latestUpload?.pipedriveId,
      },
    });
  };

  const handleForceCrawlByDomain = async () => {
    if (!crawlDomain) return;
    const result = await startCrawl({
      variables: {
        domain: crawlDomain,
        forceDeepAnalysis: true,
        pipedriveId: latestUpload?.pipedriveId,
      },
    });

    if (result?.data?.startCrawler?.success) {
      setCrawlDomain('');
    }
  };

  const handleDeleteAllBMUploads = async () => {
    if (!latestUpload?.pipedriveId) return;

    const result = await deleteAllBMUploads({
      variables: {
        companyId: metaDataResult?.uploadedMetaDataByPipedriveId?.company?.dbId,
      },
    });

    if (result?.data?.deleteAllBmForCompany?.success) {
      await refetchMetaData();
      setLatestUploadRemoved(true);
    }
  };

  const searchColumnCategories = [
    {
      field: 'shortName',
      headerName: t('searchPage.shortNameColumnField'),
      width: 200,
    },
    {
      field: 'uniqueDomain',
      headerName: t('searchPage.uniqueDomainColumnField'),
      width: 200,
    },
    {
      field: 'country',
      headerName: t('searchPage.countryColumnField'),
      width: 100,
      renderCell: (params) => {
        return params?.formattedValue ? (
          <img
            src={FLAG_URL(params?.formattedValue)}
            width={32}
            height={32}
            alt="Company country flag"
          />
        ) : (
          ''
        );
      },
    },
    {
      field: 'validated',
      headerName: t('searchPage.validatedColumnField'),
      renderCell: (params) => {
        return (
          <Chip
            sx={
              params?.formattedValue
                ? validateBenchmarkingExcelStyles.validatedChip
                : validateBenchmarkingExcelStyles.notValidatedChip
            }
            label={
              params?.formattedValue
                ? t('searchPage.validatedChipLabel')
                : t('searchPage.notValidatedChipLabel')
            }
            size="small"
          />
        );
      },
      width: 150,
    },
    {
      field: 'isSoftwareCompany',
      headerName: t('searchPage.isSoftwareFirmColumnField'),
      renderCell: (params) => {
        return params?.row?.isSoftwareCompany ? <DoneIcon /> : <CloseIcon />;
      },
      width: 150,
    },
    {
      field: 'doNotCrawl',
      headerName: t('searchPage.doNotCrawlColumnField'),
      renderCell: (params) => {
        const doNotCrawl = params?.formattedValue?.doNotCrawl;
        return doNotCrawl ? <CloseIcon /> : null;
      },
      width: 150,
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      width: 350,
      renderCell: (params) => (
        <IconButton size="small" onClick={() => handleLinkCompany(params?.row?.dbId)}>
          <Tooltip title={t('benchmarking.linkCompanyTooltip')}>
            <AddLinkOutlinedIcon />
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  return (
    <Box>
      {metaDataResultLoading ? (
        <Box gap={2} display="flex">
          <Typography>{t('benchmarking.loadingMetadataIndicator')}</Typography>
          <CircularProgress size={20} />
        </Box>
      ) : metaDataResult && metaDataResult.uploadedMetaDataByPipedriveId ? (
        <>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={{ xs: 2, sm: 0 }}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Box>
                <Typography variant="h6">{t('benchmarking.latestBMUploadTitle')}</Typography>
                <Typography>
                  {t('benchmarking.companyNameText')}{' '}
                  {metaDataResult.uploadedMetaDataByPipedriveId.company.shortName}
                </Typography>
                <Typography>
                  {t('benchmarking.uniqueDomainText')}{' '}
                  {metaDataResult.uploadedMetaDataByPipedriveId.company.uniqueDomain}
                </Typography>
                <Typography>
                  {t('benchmarking.uploadTimeText')}{' '}
                  {metaDataResult.uploadedMetaDataByPipedriveId.uploadTime
                    ? new Date(
                        metaDataResult.uploadedMetaDataByPipedriveId.uploadTime,
                      ).toLocaleString('en-GB', { timeZone: 'Europe/Berlin' })
                    : t('benchmarking.neverText')}
                </Typography>
                <Typography>
                  {t('benchmarking.uploaderNameText')}{' '}
                  {metaDataResult.uploadedMetaDataByPipedriveId.uploaderName}
                </Typography>
              </Box>
            </Grid>

            <Grid
              size={{ xs: 12, sm: 6 }}
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              {latestUpload?.pipedriveId && !latestUploadRemoved ? (
                <Button
                  disabled={!latestUpload?.pipedriveId}
                  onClick={handleDeleteAllBMUploads}
                  variant="contained"
                >
                  {t('benchmarking.buttonDeleteOldBMUploads')}
                </Button>
              ) : (
                <Typography> {t('benchmarking.noPreviousUploads')}</Typography>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box>
            <Typography variant="h6">
              {t('benchmarking.notUploadedCompanyWithPipedriveIdText', {
                pipedriveId: latestUpload?.pipedriveId,
              })}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={4} mt={6}>
            <Search
              searchTerm={searchTerm}
              handleInputChange={handleInputChange}
              handleReset={handleReset}
            />
            <DataGrid
              paginationMode="server"
              loading={isLoading || getCompaniesLoading}
              getRowId={(row) => row?.dbId}
              disableRowSelectionOnClick
              rows={companiesNodes || []}
              columns={searchColumnCategories}
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              rowHeight={35}
              initialState={{
                pagination: {
                  rowCount: -1,
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
            />
            <Grid container spacing={2}>
              <Grid size={10}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder={t('benchmarking.crawlTextFieldPlaceholder')}
                  value={crawlDomain}
                  onChange={(e) => setCrawlDomain(e.target.value)}
                />
              </Grid>
              <Grid size={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleForceCrawlByDomain}
                  disabled={!crawlDomain || startCrawlLoading}
                >
                  {t('searchPage.forceCrawlButton')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Divider sx={{ my: 2 }} />
      <Box>
        <Typography variant="h6">{t('benchmarking.dataInThisExcel')}</Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: 2 }}>
          <Typography sx={{ color: excelData.metaData !== null ? 'initial' : 'grey' }}>
            {t('benchmarking.metaDataISteering')}
          </Typography>
          {excelData.metaData ? <SmallCheckIcon /> : <SmallCloseIcon />}

          <Typography sx={{ color: excelData.earliestYear !== null ? 'initial' : 'grey' }}>
            {t('benchmarking.earliestYear')}
          </Typography>
          {excelData.earliestYear !== null ? <SmallCheckIcon /> : <SmallCloseIcon />}

          <Typography sx={{ color: excelData.plData !== null ? 'initial' : 'grey' }}>
            {t('benchmarking.plData')}
          </Typography>
          {excelData.plData ? <SmallCheckIcon /> : <SmallCloseIcon />}

          <Typography sx={{ color: excelData.bsData !== null ? 'initial' : 'grey' }}>
            {t('benchmarking.bsData')}
          </Typography>
          {excelData.bsData ? <SmallCheckIcon /> : <SmallCloseIcon />}

          <Typography sx={{ color: excelData.customerData !== null ? 'initial' : 'grey' }}>
            {t('benchmarking.customerData')}
          </Typography>
          {excelData.customerData ? <SmallCheckIcon /> : <SmallCloseIcon />}

          <Typography sx={{ color: excelData.customerList !== null ? 'initial' : 'grey' }}>
            {t('benchmarking.customerList')}
          </Typography>
          {excelData.customerList ? <SmallCheckIcon /> : <SmallCloseIcon />}

          <Typography sx={{ color: excelData.fteData !== null ? 'initial' : 'grey' }}>
            {t('benchmarking.fteData')}
          </Typography>
          {excelData.fteData ? <SmallCheckIcon /> : <SmallCloseIcon />}

          <Typography sx={{ color: excelData.pricingData !== null ? 'initial' : 'grey' }}>
            {t('benchmarking.pricingData')}
          </Typography>
          {excelData.pricingData ? <SmallCheckIcon /> : <SmallCloseIcon />}
        </Box>
      </Box>
      <CustomBackdrop
        open={isEditCompanyLoading || startCrawlLoading || deleteAllBMUploadsLoading}
      />
    </Box>
  );
};

export default ValidateBenchmarkingExcel;
