import { styled } from '@mui/material/styles';
import { FileManagerComponent } from '@syncfusion/ej2-react-filemanager';

const StyledFileManager = styled(FileManagerComponent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,

  '.e-toolbar': {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
  },

  '.e-treeview': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },

  '.e-btn': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  '.e-grid': {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,

    '& .e-gridcontent': {
      backgroundColor: theme.palette.background.default,
      '.e-content': {
        backgroundColor: theme.palette.background.default,
      },

      '.e-table': {
        borderCollapse: 'collapse',
        backgroundColor: theme.palette.background.default,

        '.e-row': {
          borderBottom: `1px solid ${theme.palette.divider}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          '&.e-altrow': {
            backgroundColor: theme.palette.action.hover,
          },

          '.e-rowcell': {
            color: theme.palette.text.primary,
            padding: '8px',

            '&.e-templatecell': {
              '.e-fe-text': {
                color: theme.palette.text.primary,
              },
              '.e-fe-size': {
                color: theme.palette.text.secondary,
              },
            },
          },
        },
      },
    },
  },
}));

export default StyledFileManager;
