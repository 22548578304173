export const formatUrl = (input: string) => {
  if (!input) return { isValid: false, url: null };

  input = input.trim();

  try {
    if (input.match(/^https?:\/\//)) {
      const url = new URL(input);

      const domain = url.hostname.replace(/^www\./, '');

      if (!domain.match(/^[a-zA-Z0-9][a-zA-Z0-9-]*\.[a-zA-Z]{2,}$/)) {
        return { isValid: false, url: null };
      }

      return { isValid: true, url: `${url.protocol}//${domain}` };
    }

    input = input.replace(/^www\./, '');

    if (input.match(/^[a-zA-Z0-9][a-zA-Z0-9-]*\.[a-zA-Z]{2,}$/)) {
      return { isValid: true, url: `https://${input}` };
    }

    return { isValid: false, url: null };
  } catch {
    return { isValid: false, url: null };
  }
};
