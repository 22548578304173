import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as XLSX from 'xlsx';
import { throttle } from 'lodash';

import {
  useExcelUploadFileMutation,
  useExcelUploadRowMutation,
  useExcelUploadSheetMutation,
  useFinishExcelFileUploadMutation,
} from '../../../gql/generated/graphql';
import { useSnackbar } from '../../../providers/SnackbarProvider';

type UploadExcelFileProps = {
  fileContents: ArrayBuffer | null;
  companyId: number;
  fileName: string;
  uploaderName: string;
  disableUpload?: boolean;
  onProgress?: (progress: number) => void;
  onSheetCompletion?: (sheetName: string) => void;
  onFileCompletion?: (fileID: number) => void;
};

const BATCH_SIZE = 50;
const MAX_CONCURRENT_UPLOADS = 5;

const UploadExcelFile = ({
  fileContents,
  companyId,
  fileName,
  uploaderName,
  disableUpload = false,
  onProgress,
  onSheetCompletion,
  onFileCompletion,
}: UploadExcelFileProps) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadExcelFile, { loading: uploadExcelFileLoading }] = useExcelUploadFileMutation();
  const [uploadExcelSheet, { loading: uploadExcelSheetLoading }] = useExcelUploadSheetMutation();
  const [uploadExcelRow, { loading: uploadExcelRowLoading }] = useExcelUploadRowMutation();
  const [finishFileUpload, { loading: fileUploadingMutationLoading }] =
    useFinishExcelFileUploadMutation();

  const sheetsToUpload: string[] = [
    'I_Steering',
    'PnL',
    'BS',
    'FTE',
    'Customers_aggregated',
    'Customers_list',
    'Pricing',
  ];

  const retryUpload = async (mutationFunc, variables, retries = 3) => {
    let attempt = 0;
    while (attempt < retries) {
      try {
        const response = await mutationFunc({ variables });
        if (response?.data) return response;
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed`);
        attempt++;
        if (attempt >= retries) {
          showSnackbar('Failed after retries', 'error', false);
          throw error;
        }
      }
    }
  };

  const handleExcelUpload = async () => {
    if (!fileContents) {
      console.error('No file contents provided');
      return;
    }
    setIsFileUploading(true);

    const workbook = XLSX.read(new Uint8Array(fileContents), { type: 'array' });
    const totalSheets = workbook.SheetNames.length;
    let totalProgress = 0;
    let currentRowNumber = 1; // Start at row 1

    const updateProgressThrottled = throttle((progress) => {
      if (onProgress) {
        onProgress(progress);
      }
    }, 1000);

    // Step 1: Upload the file and get the fileId
    const fileResponse = await retryUpload(uploadExcelFile, {
      input: {
        companyId,
        fileName,
        uploaderName,
        isBenchmarkingFile: true,
        isCustomerFile: false,
        isLboFile: false,
      },
    });

    const fileId = fileResponse.data?.uploadExcelFile?.file?.id;
    if (!fileResponse.data?.uploadExcelFile?.ok || !fileId) {
      console.error('Failed to create file record');
      showSnackbar('Failed to create file record', 'error', false);
      setIsFileUploading(false);
      return;
    }

    // Step 2: Upload sheets and rows
    const concurrentUploads = [];
    for (let i = 0; i < totalSheets; i++) {
      currentRowNumber = 1; // Reset row number for each sheet
      const sheetName = workbook.SheetNames[i];
      const sheet = workbook.Sheets[sheetName];

      if (!sheetsToUpload.includes(sheetName)) {
        continue; // Skip sheets not in the list
      }

      // Step 2a: Upload the sheet
      const sheetResponse = await retryUpload(uploadExcelSheet, {
        input: {
          excelFileId: Number(fileId),
          sheetName: sheetName,
        },
      });

      const sheetId = sheetResponse.data?.uploadExcelSheet?.sheet?.id;
      if (!sheetId) {
        console.error('Failed to create sheet');
        showSnackbar('Failed to create sheet', 'error', false);
        continue;
      }

      // Step 2b: Upload rows in batches, passing the current starting row
      const rows: (string | number | null)[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const totalRows = rows.length;

      for (let batchStart = 0; batchStart < totalRows; batchStart += BATCH_SIZE) {
        const batch = rows.slice(batchStart, batchStart + BATCH_SIZE);

        const batchPromise = retryUpload(uploadExcelRow, {
          input: {
            sheetId: Number(sheetId),
            startRow: currentRowNumber, // Pass the starting row for the batch
            rows: batch.map((row) => ({
              cells: row.map((cell) => (cell !== null && cell !== undefined ? String(cell) : '')),
            })),
          },
        });
        concurrentUploads.push(batchPromise);

        currentRowNumber += batch.length; // Increment row number by the number of rows in the batch

        // Update progress
        totalProgress += (BATCH_SIZE / (totalSheets * totalRows)) * 100;
        updateProgressThrottled(Math.min(totalProgress, 100));

        if (concurrentUploads.length >= MAX_CONCURRENT_UPLOADS) {
          await Promise.all(concurrentUploads);
          concurrentUploads.length = 0; // Reset after processing batch
        }
      }

      // Call the onSheetCompletion callback if provided
      if (onSheetCompletion) {
        onSheetCompletion(sheetName);
      }
    }

    // Wait for any remaining concurrent uploads
    await Promise.all(concurrentUploads).catch(() => {
      showSnackbar('Failed to upload rows', 'error', false);
    });

    // Step 3: Mark the file as completed
    const finishFileResponse = await retryUpload(finishFileUpload, {
      input: parseInt(fileId, 10),
    });
    if (!finishFileResponse?.data?.finishExcelFileUpload?.ok) {
      showSnackbar('Failed to finish file upload', 'error', false);
    }

    // Call the onFileCompletion callback if provided
    if (onFileCompletion) {
      onFileCompletion(Number(fileId));
    }

    setIsFileUploading(false);
  };

  const isUploading =
    isFileUploading ||
    fileUploadingMutationLoading ||
    uploadExcelFileLoading ||
    uploadExcelSheetLoading ||
    uploadExcelRowLoading;

  return (
    <div>
      <Button
        component="label"
        fullWidth
        variant="contained"
        disabled={isUploading || disableUpload}
        sx={{ flexGrow: 1 }}
        onClick={handleExcelUpload}
      >
        {isUploading ? (
          <Box display="flex" gap={2}>
            <Typography>{t('benchmarking.uploadFileLoadingIndicator')}</Typography>
            <CircularProgress size={20} color="secondary" />
          </Box>
        ) : (
          t('benchmarking.uploadToDBButton')
        )}
      </Button>
    </div>
  );
};

export default UploadExcelFile;
