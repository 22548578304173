import React from 'react';
import { useQuery, gql } from '@apollo/client';

// Define the GraphQL query
const GET_MARKET_SEGMENTS = gql`
  query GetMarketSegments($companyId: ID!) {
    marketSegments(companyId: $companyId) {
      id
      name
      description
    }
  }
`;

const MarketSegmentPage: React.FC<{ companyId: string }> = ({ companyId }) => {
  // Use the query hook
  const { loading, error, data } = useQuery(GET_MARKET_SEGMENTS, {
    variables: { companyId },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h1>Market Segments</h1>
      <ul>
        {data.marketSegments.map((segment: { id: string; name: string; description: string }) => (
          <li key={segment.id}>
            <h2>{segment.name}</h2>
            <p>{segment.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MarketSegmentPage;
