import '../../syncfusionStyles';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box, Dialog, Typography } from '@mui/material';
import {
  DetailsView,
  FileSelectEventArgs,
  NavigationPane,
} from '@syncfusion/ej2-react-filemanager';
import {
  Annotation,
  BookmarkView,
  FormDesigner,
  FormFields,
  Inject,
  LinkAnnotation,
  Magnification,
  Navigation,
  PdfViewerComponent,
  Print,
  TextSearch,
  TextSelection,
  ThumbnailView,
  Toolbar,
} from '@syncfusion/ej2-react-pdfviewer';

import StyledFileManager from './components/StyledFileManager';
import { FileType } from './types';

const CompanyFilesPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedFileDetails, setSelectedFileDetails] = useState<FileType | null>(null);
  const [pdfFileUrl, setPdfFileUrl] = useState<string | null>(null);

  const queryParams = new URLSearchParams(location.search);
  const companyId = queryParams.get('companyId');
  //const hostUrl = 'https://ej2-aspcore-service.azurewebsites.net/';
  const hostUrl = process.env.REACT_APP_FILESERVER_BACKEND;

  // Handle file open event
  const handleFileOpen = (args: any) => {
    console.log('File open event triggered:', args);

    // Check if `isFile` is true
    if (args.fileDetails.isFile) {
      console.log('This is a file.');
    } else {
      console.log('This is not a file.');
    }

    // Check if `type` is `.pdf`
    if (args.fileDetails.type === 'pdf') {
      console.log('File is of type PDF.');
    } else if (!args.fileDetails.isFile) {
      console.log('File is not of type PDF; it is a folder  or other type.');
    } else {
      console.log(`File is not of type PDF; it is of type ${args}.`);
      // download the file
      window.open(
        `${hostUrl}/${companyId}/download?path=${encodeURIComponent(args.fileDetails.name)}`,
      );
    }
    // Proceed if both conditions are true
    if (args.fileDetails.isFile && args.fileDetails.type === 'pdf') {
      console.log('PDF file detected:', args.fileDetails.name);
      console.log('PDF file path:', args);
      // Example Base64 string for testing
      const filePath = `${hostUrl}/${companyId}/download?path=${encodeURIComponent(args.fileDetails.name)}`;
      console.log('PDF file data:', filePath);
      setPdfFileUrl(filePath);
      // console.log('PDF file data set in state to open PDF viewer:', filePath);
    }
  };

  // Function to close the PDF viewer
  const closePdfViewer = () => {
    // setPdfFileUrl('');
    console.log('PDF viewer closed.');
    // get the PDFViewer with the given ID and invoker the unload method
    const pdfViewer = document.getElementById('pdf-viewer');
    if (pdfViewer) {
      console.log('PDF viewer found:', pdfViewer);
      // @ts-ignore
      pdfViewer.ej2_instances[0].unload();
    } else {
      console.log('PDF viewer not found.');
    }
    // Now let's close the containing dialog
    setPdfFileUrl(null);
  };

  const handleFileSelect = (args: FileSelectEventArgs) => {
    if (args && args.fileDetails) {
      setSelectedFileDetails(args.fileDetails as FileType);
    }
  };

  const selectedFileModifiedDate =
    selectedFileDetails?.dateModified &&
    new Date(selectedFileDetails.dateModified).toLocaleString();

  const metadataFileValue = (value: string | number) => value || '';

  return (
    <div className="company-files-page">
      <Typography variant="h4" gutterBottom>
        {t('companyFilesPage.title')}
      </Typography>
      <div className="control-section">
        <StyledFileManager
          id="file-manager"
          view="Details"
          // cssClass="e-custom-style" // use the style for cortex in general
          enableVirtualization={false}
          allowDragAndDrop={true}
          rootAliasName="Company Files" // Like this we can influence what root folder is shown. Not a priority
          uploadSettings={{
            autoClose: true,
            directoryUpload: false,
            minFileSize: 0,
            maxFileSize: 30000000,
            autoUpload: true,
          }}
          ajaxSettings={{
            url: `${hostUrl}/${companyId}`, // Corrected interpolation
            downloadUrl: `${hostUrl}/${companyId}/download`, // Corrected interpolation
            uploadUrl: `${hostUrl}/${companyId}/upload`, // Corrected interpolation
            getImageUrl: `${hostUrl}/${companyId}/get-image`, // Corrected interpolation
          }}
          fileOpen={handleFileOpen}
          fileSelect={handleFileSelect}
        >
          <Inject services={[NavigationPane, Toolbar, DetailsView]} />
        </StyledFileManager>
      </div>
      {selectedFileDetails && (
        <Box p={4}>
          <Typography variant="h6" gutterBottom>
            {t('companyFilesPage.fileMetadataTitle')}
          </Typography>
          {selectedFileDetails && (
            <div>
              <Typography>
                <strong>{t('companyFilesPage.fileMetadataName')}</strong>{' '}
                {metadataFileValue(selectedFileDetails.name)}
              </Typography>
              <Typography>
                <strong>{t('companyFilesPage.fileMetadataSize')}</strong>
                {metadataFileValue(selectedFileDetails.size)
                  ? ` ${selectedFileDetails.size} ${t('companyFilesPage.fileMetadataSizeBytes')}`
                  : ''}
              </Typography>
              <Typography>
                <strong>{t('companyFilesPage.fileMetadataType')}</strong>{' '}
                {metadataFileValue(selectedFileDetails.type)}
              </Typography>
              <Typography>
                <strong>{t('companyFilesPage.fileMetadataModified')}</strong>
                {` ${metadataFileValue(selectedFileModifiedDate)}`}
              </Typography>
            </div>
          )}
        </Box>
      )}

      {/* Dialog to display PDF Viewer */}
      <Dialog open={!!pdfFileUrl} onClose={closePdfViewer} maxWidth="lg" fullWidth>
        {pdfFileUrl && (
          <PdfViewerComponent
            id="pdf-viewer"
            documentPath={pdfFileUrl}
            resourceUrl="https://cdn.syncfusion.com/ej2/24.1.41/dist/ej2-pdfviewer-lib"
            style={{ height: '640px' }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                Annotation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                FormFields,
                FormDesigner,
              ]}
            />
          </PdfViewerComponent>
        )}
      </Dialog>
    </div>
  );
};

export default CompanyFilesPage;
